/* src/components/Features.css */

:root {
  --cards: 4;
  --cardHeight: 46vh;
  --cardTopPadding: 3.8em; /* valor por defecto */
  --cardMargin: 4vw;
}

.container {
  margin: 0 auto;
}

#cards {
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--cards), var(--cardHeight));
  gap: var(--cardMargin);
  padding-bottom: calc(var(--cards) * var(--cardTopPadding));
  margin-bottom: var(--cardMargin);
}

#card1 {
  --index: 1;
}

#card2 {
  --index: 2;
}

#card3 {
  --index: 3;
}

#card4 {
  --index: 4;
}

.card {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--cardTopPadding));
}

.card-body {
  position: relative;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 20px;
  height: var(--cardHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  background-color: var(--bgColor);
}

/* Estilos generales para el triángulo */
.card-body::after {
  content: '';
  position: absolute;
  bottom: -119px; /* Ajusta según necesites */
  width: 0;
  height: 0;
  border: 60px solid transparent; /* Tamaño predeterminado del triángulo */
  border-top: 60px solid var(--bgColor); /* Usa el mismo color de fondo */
}

/* Triángulo a la izquierda */
.card.left .card-body::after {
  left: 50px; /* Ajusta la posición horizontal */
}

/* Triángulo a la derecha */
.card.right .card-body::after {
  right: 50px; /* Ajusta la posición horizontal */
  transform: scaleX(-1); /* Voltea el triángulo horizontalmente */
}

/* Alineación del texto y contenido */
.card.left .card-body {
  text-align: left;
}

.card.right .card-body {
  text-align: right;
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  :root {
    --cardTopPadding: 2.8em; /* nuevo valor para pantallas pequeñas */
  }

  /* Tamaño más pequeño del triángulo en pantallas pequeñas */
  .card-body::after {
    bottom: -80px; /* Ajuste de posición en pantallas móviles */
    border-width: 40px; /* Reduce el tamaño del triángulo */
    border-top-width: 40px; /* Ajusta el borde superior para hacer el triángulo más pequeño */
  }
}
